import { useNavigate } from 'react-router-dom';

import { Warning } from '~shared/ui/warning';

import Button from '@breeze-platform-ui/button';
import { Row } from '@breeze-platform-ui/layout';
import { TuiIconTdsIllustrationsCommonDrawerEmptyLight } from '@tui-react/proprietary-icons';

export const EmptyList = () => {
  const navigate = useNavigate();
  return (
    <Warning
      title="No Skyro leads"
      icon={
        <TuiIconTdsIllustrationsCommonDrawerEmptyLight iconSize={180 as any} />
      }
      footerContent={
        <Row alignMain="center">
          <div style={{ maxWidth: '240px', width: '100%' }}>
            <Button
              onClick={() => navigate('/account')}
              wide
              theme="secondary"
              size="m"
            >
              Go back
            </Button>
          </div>
        </Row>
      }
    />
  );
};
