import type { GiftPayment } from '~entities/gift-payment';

export type ProductType = 'LOAN' | 'LOAN_ZERO_RATE';
export type ProductChannel = 'OFFLINE' | 'ONLINE';

export type ProductParams = {
  minLoanAmount: number;
  maxLoanAmount: number;
  minDownPaymentPercent: number;
  maxDownPaymentPercent: number;
  termsInMonths: number[];
  // amount of interest per month
  minMonthlyInterestRate: number;
  maxMonthlyInterestRate: number;
  // fixed processing fee
  minOriginationFeeFlat: number;
  maxOriginationFeeFlat: number;
  // processing fee per month
  minOriginationFeeRate: number;
  maxOriginationFeeRate: number;
};

export type ApprovedProductParams = {
  minLoanAmount: number;
  maxLoanAmount: number;
  minDownPaymentPercent: number;
  maxDownPaymentPercent: number;
  termsInMonths: number[];
  // amount of interest per month
  interestRate: number;
  // fixed processing fee
  originationFeeFlat: number;
  // processing fee per month
  originationFeeRate: number;
};

export type ApprovedProductOption = {
  id: string;
  minLoanAmount: number;
  maxLoanAmount: number;
  minDownPaymentPercent: number;
  maxDownPaymentPercent: number;
  monthlyInterestRate: number;
  loanTermInMonths: number;
  originationFeeFlat: number;
  originationFeeRate: number;
};

export type OrderParams = {
  minOrderTotalAmount: number;
  maxOrderTotalAmount: number;
};

export type ProductDetails = {
  productId: string;
  type: ProductType;
  channel: ProductChannel;
  campaignId?: string;
};

export type PaymentPlan = {
  term: number;
  minMonthlyPayment: number;
  maxMonthlyPayment: number;
  giftPayment?: Omit<GiftPayment, 'termInMonths'>;
};

export type ApprovedPaymentPlan = {
  approvedProductOptionId: string;
  term: number;
  monthlyPayment: number;
  giftPayment?: Omit<GiftPayment, 'termInMonths'>;
};

export type Product = ProductDetails & ProductParams & OrderParams;

export type ApprovedProduct = {
  type: 'POS_LOAN';
  approvedLoanAmount: number;
  approvedLoanTerm: number;
  downPayment: number;
  productId?: string;
  campaignId?: string;
  monthlyInterestRate: number;
  originationFeeFlat: number;
  originationFeeRate: number;
  giftPayment?: GiftPayment;
};

export function isProduct(product?: Product | OrderParams): product is Product {
  return typeof product !== 'undefined' && 'productId' in product;
}

export type ManufacturerIdType = 'IMEI' | 'SERIAL_NUMBER';

export type ZeroRateItem = {
  id: string;
  name: string;
  categoryCode: string;
  manufacturerIdType: ManufacturerIdType;
  // shows if mutli choice is available
  // after the user adds current item
  eligibleForMultiChoice?: boolean;
  // shows if item can be bundled with
  // previously added zero-rate items
  availableForSelection?: boolean;
  producerType?: 'BRAND' | 'RETAILER';
};
