import { useMemo } from 'react';

import type { SocialLeadListItem } from '~entities/social-lead';
import { SocialLeadItem } from '~entities/social-lead';

import Clickable from '@breeze-platform-ui/clickable';
import { format } from 'date-fns';

import styles from './social-leads-list.module.css';

import { groupLeadsByDate } from '../../lib/group-leads-by-date';

export const SocialLeadsList: React.FC<{
  leads: SocialLeadListItem[];

  onLeadClick: (id: string) => void;
}> = ({ leads, onLeadClick }) => {
  const leadsByDate = useMemo(() => groupLeadsByDate(leads), [leads]);

  return (
    <div className={styles.list}>
      {leadsByDate.map(({ date, items }) => {
        return (
          <div key={date}>
            <p className={styles.subtitle}>{format(date, 'LLLL d')}</p>
            <div>
              {items.map((lead) => (
                <Clickable
                  key={lead.id}
                  className={styles.clickable}
                  onClick={() => onLeadClick(lead.id)}
                >
                  <div className={styles.content}>
                    <SocialLeadItem {...lead} />
                  </div>
                </Clickable>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
