import { isHttpError } from '~shared/errors/validators';

import { useQuery } from '@tanstack/react-query';

import { gatewaySocialLeadsApi } from './gateway-social-leads-api';
import { socialLeadsKeys } from './social-leads-keys';

export async function fetchSocialLead(id: string) {
  try {
    return await gatewaySocialLeadsApi.getLead(id);
  } catch (err: any) {
    if (isHttpError(err) && err.status === 404) {
      return null;
    }

    throw err;
  }
}

export function useSocialLeadsList() {
  return useQuery({
    queryKey: socialLeadsKeys.list(),
    queryFn: async () => gatewaySocialLeadsApi.getLeads(),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
}

export function useSocialLead(id: string) {
  return useQuery({
    queryKey: socialLeadsKeys.lead(id),
    queryFn: () => fetchSocialLead(id),
    refetchOnWindowFocus: true,
  });
}
