import { isAfter } from 'date-fns';

export function groupLeadsByDate<T extends { createdAt: string }>(items: T[]) {
  const dateToItemsMap = items.reduce((result, item) => {
    const dateWithoutTime = item.createdAt.split('T')[0];
    if (!result[dateWithoutTime]) {
      // eslint-disable-next-line no-param-reassign
      result[dateWithoutTime] = [];
    }

    result[dateWithoutTime].push(item);
    return result;
  }, {} as Record<string, T[]>);

  return Object.keys(dateToItemsMap)
    .sort((date1, date2) => {
      return isAfter(date2, date1) ? 1 : -1;
    })
    .map((date) => ({ date, items: dateToItemsMap[date] }));
}
