import { useNavigate } from 'react-router-dom';

import { MarketingLeadsList } from '~widgets/marketing-leads';

import { useGetMarketingLeads } from '~entities/marketing-lead';

import { NavBackButton } from '~shared/ui/nav-back-button';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

export const SkyroLeadsPage: React.FC = () => {
  const leadsQuery = useGetMarketingLeads();
  const navigate = useNavigate();

  return (
    <Screen header={<NavBackButton onClick={() => navigate('/account')} />}>
      <ScreenTitle title="Skyro leads" />
      <MarketingLeadsList
        leads={leadsQuery.data}
        isLoading={leadsQuery.isLoading}
        onLeadClick={(id) => navigate(`/skyro-leads/${id}`)}
      />
    </Screen>
  );
};
