import type { CustomerFeedback } from '../marketing-lead';

export const feedbackToTextMap: Record<CustomerFeedback, string> = {
  INTERESTED: 'Interested',
  NOT_INTERESTED: 'Not interested',
  HAVENT_ANSWERED: 'Not answering',
  APPLIED_BUT_REJECTED: 'Applied but rejected',
  UTILIZED: 'Utilized',
  UNDECIDED: 'Undecided',
  CANNOT_BE_REACHED_WRONG_NUMBER: 'Cannot be reached/Wrong number',
};
