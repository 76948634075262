import { GiftPaymentBadge } from '~entities/gift-payment';
import type { ApprovedPaymentPlan } from '~entities/product';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { plural } from '~shared/lib/plural';

import { Box, Col, Row } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money';
import Text from '@breeze-platform-ui/text';

const loanMoneyProps = { ...phMoneyProps, precision: 2 };

export function getPaymentPlanOptions(paymentPlans: ApprovedPaymentPlan[]) {
  return paymentPlans.map(
    ({ approvedProductOptionId, term, monthlyPayment, giftPayment }) => {
      return {
        value: `${approvedProductOptionId}`,
        children: (
          <Row alignMain="between" width="100%">
            <Col alignCross="stretch" grow={1}>
              <Text bold size={15} color="black">
                <Money {...loanMoneyProps} value={monthlyPayment} />
              </Text>
              <Text size={13} color="black">
                x {term} {plural(term, 'month', 'months')}
              </Text>
            </Col>
            {giftPayment && (
              <Box padding="8px 0">
                <GiftPaymentBadge amount={giftPayment.amount} />
              </Box>
            )}
          </Row>
        ),
      };
    }
  );
}
