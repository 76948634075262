import type {
  ConversationDetails,
  MarketingLead,
} from '~entities/marketing-lead';

import { endOfDay, format, parse } from 'date-fns';

export function recoverConversationDetails(
  details?: MarketingLead['conversationDetails']
): ConversationDetails {
  return {
    ...details,
    plannedDateOfVisit: details?.plannedDateOfVisit
      ? format(details?.plannedDateOfVisit, 'MM/dd/yyyy')
      : '',
  };
}

export function formatConversationDetails(
  details?: ConversationDetails
): ConversationDetails {
  return {
    feedback: details?.feedback || undefined,
    comment: details?.comment?.trim() || undefined,
    plannedDateOfVisit: details?.plannedDateOfVisit
      ? // we need to send end of day to guarantee that the planned date of visit is in the future
        endOfDay(
          parse(details.plannedDateOfVisit, 'MM/dd/yyyy', new Date())
        ).toISOString()
      : undefined,
  };
}
