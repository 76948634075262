import { useQuery } from '@tanstack/react-query';

import { gatewayMarketingLeadsApi } from './marketing-leads-api';
import { leadsKeys } from './marketing-leads-keys';

import type { MarketingLead } from '../marketing-lead';

export function useGetMarketingLeads<TSelect = MarketingLead[]>(options?: {
  select?: (value: MarketingLead[]) => TSelect;
}) {
  return useQuery({
    queryKey: leadsKeys.all(),
    queryFn: async () => gatewayMarketingLeadsApi.getLeads(),
    retry: 3,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    ...options,
  });
}
