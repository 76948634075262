import { environment } from '~app/environment';

import type { RequestParams, ScpClient } from '~shared/scp-client';
import { webRequestClient } from '~shared/scp-client';

import type {
  SocialLeadsApi,
  SubmitLeadFormResponse,
  UpdateConversationDetailsPayload,
} from './social-leads-api';

import type {
  SocialLead,
  NewSocialLead,
  SocialLeadListItem,
} from '../social-lead';

export class GatewaySocialLeadsApi implements SocialLeadsApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: { host: string }) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  getLeads(): Promise<SocialLeadListItem[]> {
    return this.makeRequest<SocialLeadListItem[]>({
      method: 'get-list',
    });
  }

  getLead(id: string): Promise<SocialLead> {
    return this.makeRequest<SocialLead>({
      method: 'get-details',
      payload: { id },
    });
  }

  submit(payload: NewSocialLead): Promise<SubmitLeadFormResponse> {
    const formData = new FormData();
    const { screenshots, ...form } = payload;

    formData.append('form', JSON.stringify(form));
    screenshots.forEach((item) =>
      formData.append(`screenshots`, item.file, item.file.name)
    );

    return this.makeRequest<SubmitLeadFormResponse>({
      type: 'multipart/form-data',
      method: 'submit-form',
      payload: formData,
    });
  }

  updateConversationDetails(
    payload: UpdateConversationDetailsPayload
  ): Promise<void> {
    return this.makeRequest({
      method: 'update-conversation-details',
      payload,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'marketing',
        service: 'leads/web/social',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewaySocialLeadsApi = new GatewaySocialLeadsApi(
  webRequestClient,
  {
    host: environment.API_HOST,
  }
);
