import { calculateItemsCount } from '~entities/order-item/lib';

import { plural } from '~shared/lib/plural';
import { nonNullableValue } from '~shared/types/non-nullable-value';

import {
  isApproved,
  isCancelled,
  isOldOnboarding,
  isSelfAppMobileOnboarding,
  isSelfAppAgentFlowOnboarding,
  isPreApproved,
  isReleased,
  isWaitingForDecision,
  isRejected,
  isDownPaymentRequired,
  isMobileAppRequired,
  isReviewingCustomer,
} from './status-checkers';

import type { ApplicationDetails } from '../application';

export type Status =
  | 'onboarding'
  | 'selfAppMobileOnboarding'
  | 'preApproved'
  | 'waiting'
  | 'mobileApp'
  | 'reviewing'
  | 'approved'
  | 'rejected'
  | 'actionRequired'
  | 'cancelled'
  | 'finalized';

export type ApplicationProcessStatus = {
  status: Status;
  message: string;
  dateUTC: string;
};
/* eslint-disable max-statements */
export const getApplicationProcessStatus = (
  applicationDetails: ApplicationDetails
): ApplicationProcessStatus => {
  const { lastUpdatedUTC, applicationStatusChangedUTC, orderItems } =
    applicationDetails;

  if (isCancelled(applicationDetails)) {
    return {
      status: 'cancelled',
      message: 'Application cancelled',
      dateUTC: lastUpdatedUTC,
    };
  }

  if (isSelfAppMobileOnboarding(applicationDetails)) {
    return {
      status: 'selfAppMobileOnboarding',
      message: 'Applying in the app',
      dateUTC: applicationStatusChangedUTC,
    };
  }
  if (
    isOldOnboarding(applicationDetails) ||
    isSelfAppAgentFlowOnboarding(applicationDetails)
  ) {
    return {
      status: 'onboarding',
      message: 'Onboarding',
      dateUTC: applicationStatusChangedUTC,
    };
  }

  if (isPreApproved(applicationDetails)) {
    return {
      status: 'preApproved',
      message: 'Pre-approved',
      dateUTC: applicationStatusChangedUTC,
    };
  }
  if (isWaitingForDecision(applicationDetails)) {
    return {
      status: 'waiting',
      message: 'Waiting for decision',
      dateUTC: applicationStatusChangedUTC,
    };
  }

  if (isMobileAppRequired(applicationDetails)) {
    return {
      status: 'mobileApp',
      message: 'Customer needs to install the Skyro app',
      dateUTC: applicationStatusChangedUTC,
    };
  }

  if (isReviewingCustomer(applicationDetails)) {
    return {
      status: 'reviewing',
      message: 'Reviewing customer’s information',
      dateUTC: applicationStatusChangedUTC,
    };
  }

  if (isApproved(applicationDetails)) {
    return {
      status: 'approved',
      message: 'Approved',
      dateUTC: applicationStatusChangedUTC,
    };
  }

  if (isRejected(applicationDetails)) {
    return {
      status: 'rejected',
      message: 'Rejected',
      dateUTC: applicationStatusChangedUTC,
    };
  }

  if (isDownPaymentRequired(applicationDetails)) {
    return {
      status: 'actionRequired',
      message: 'Down payment required',
      dateUTC: lastUpdatedUTC,
    };
  }

  if (isReleased(applicationDetails)) {
    const amount = calculateItemsCount(nonNullableValue(orderItems));
    const itemText = plural(amount, 'Item', 'Items');

    return {
      status: 'finalized',
      message: `${itemText} received`,
      dateUTC: lastUpdatedUTC,
    };
  }

  // TODO: error handling for incorrect statuses combination
  return {
    status: 'finalized',
    message: `Incorrect status`,
    dateUTC: lastUpdatedUTC,
  };
};
/* eslint-enable max-statements */
