/* eslint-disable max-statements */
import { useCallback, useRef, useState } from 'react';
import { useNavigate, useLocation, useBlocker } from 'react-router-dom';

import {
  useCalculatorAnalytics,
  useCustomerAnalytics,
} from '~app/providers/analytics-provider';

import type { ClientInitiationNavigationState } from '~pages/client-initiation';

import {
  LoanCalculator,
  getProductTypeFromItems,
  type LoanCalculatorFormValues,
} from '~widgets/loan-calculator';

import { useShouldForceAttendanceLog } from '~features/check-attendance';
import { LogAttendance } from '~features/log-attendance';
import type { ActiveOffer } from '~features/manage-offer';

import { AttendanceWarning } from '~entities/attendance';
import { userInfoAtom } from '~entities/auth';
import { ForceOfferBanner } from '~entities/offer';
import { selectedPosAtom } from '~entities/pos';
import type { ProductType } from '~entities/product';
import { WithProductType } from '~entities/product';

import { useOnMountEffect } from '~shared/hooks';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';

import Sheet from '@breeze-platform-ui/sheet';
import { generateUid } from '@breeze/web-lib-wuid';
import { useAtomValue } from 'jotai';

import { ConfirmationPopup, ConfirmationSurvey } from './confirmation';
import { Header } from './header/header';
import styles from './loan-calculator-page.module.css';

import { getCalculatorAnalyticsPayload } from '../lib';

export type PreCalcNavigationState =
  | {
      loanCalculator?: Partial<LoanCalculatorFormValues>;
      productType?: ProductType;
      calculatorSessionId?: string;
      forcedOffer?: ActiveOffer;
      preApprovedApplication?: {
        applicationId: string;
        phoneNumber: string;
      };
    }
  | undefined;

export const LoanCalculatorPage = () => {
  const navigate = useNavigate();
  const calculatorAnalytics = useCalculatorAnalytics();
  const customerAnalytics = useCustomerAnalytics();
  const { state: navigationState } = useLocation();

  const blocker = useBlocker(({ historyAction }) => historyAction === 'POP');

  const selectedPos = useAtomValue(selectedPosAtom);
  const userInfo = useAtomValue(userInfoAtom);

  const {
    loanCalculator: initialValue,
    forcedOffer,
    calculatorSessionId,
    preApprovedApplication,
  } = (navigationState as PreCalcNavigationState | undefined) || {};

  const sessionIdRef = useRef(calculatorSessionId || generateUid());

  useOnMountEffect(() => {
    if (preApprovedApplication) {
      customerAnalytics.setUserId(preApprovedApplication.applicationId);
    }
    calculatorAnalytics.setUserId(sessionIdRef.current);
  });

  const [confirmationShown, setConfirmationShown] = useState(false);
  const [cancelSurveyShown, setCancelSurveyShown] = useState(false);
  const [forcedOfferShown, setForcedOfferShown] = useState<boolean>(
    !!forcedOffer
  );
  const [isOfferCancelled, setIsOfferCancelled] = useState<boolean>(false);

  const [isAttendanceLogInProgress, setAttendanceLogInProgress] =
    useState(false);
  const [isAttendanceSheetOpened, setAttendanceSheetOpened] = useState(false);

  const shouldCheckAttendance = useShouldForceAttendanceLog();
  const initialOfferRef = useRef<ActiveOffer | undefined>(forcedOffer);

  const productTypeFromItems = getProductTypeFromItems(
    !!selectedPos?.hasZeroRateProducts,
    initialValue?.items
  );
  const [productType, setProductType] = useState<ProductType>(() => {
    if (productTypeFromItems) {
      return productTypeFromItems;
    }

    return selectedPos?.hasZeroRateProducts ? 'LOAN_ZERO_RATE' : 'LOAN';
  });

  const zeroRateInitialValue =
    (productTypeFromItems === 'LOAN_ZERO_RATE' && initialValue) || {};
  const loanInitialValue =
    (productTypeFromItems === 'LOAN' && initialValue) || {};
  const loanCalculatorValuesRef = useRef<
    Record<ProductType, Partial<LoanCalculatorFormValues>>
  >({ LOAN: loanInitialValue, LOAN_ZERO_RATE: zeroRateInitialValue });

  const trackCalculatorStateUpdate = (cancellationReason?: string) => {
    const loanCalcValues = loanCalculatorValuesRef.current[productType];
    const payload = {
      ...getCalculatorAnalyticsPayload({
        userInfo,
        selectedPos,
        productType,
        loanCalcValues,
        forcedOffer,
      }),
      cancellationReason,
    };
    calculatorAnalytics.trackCalculatorUpdate(payload);
    if (preApprovedApplication) {
      customerAnalytics.trackPreApprovedCalculatorUpdate(payload);
    }
  };

  const handleUpdate = (values: Partial<LoanCalculatorFormValues>) => {
    loanCalculatorValuesRef.current[productType] = values;
    trackCalculatorStateUpdate();
    const state: PreCalcNavigationState = {
      loanCalculator: values,
      productType,
      calculatorSessionId: sessionIdRef.current,
      preApprovedApplication,
    };
    navigate('/loan-calculator', {
      state,
      replace: true,
    });
  };

  const handleSubmit = (values: LoanCalculatorFormValues) => {
    loanCalculatorValuesRef.current[productType] = values;
    trackCalculatorStateUpdate();

    const state: ClientInitiationNavigationState = {
      loanCalculator: values,
      productType,
      forcedOfferAppliedId: initialOfferRef.current?.offer.id,
      calculatorSessionId: sessionIdRef.current,
      isOfferCancelled: !values.offer && isOfferCancelled,
    };

    if (shouldCheckAttendance.shouldForce) {
      setAttendanceSheetOpened(true);
      return;
    }

    if (preApprovedApplication) {
      navigate(
        `/self-application-agent/${preApprovedApplication.applicationId}`,
        {
          state,
        }
      );
    } else {
      navigate('/client-initiation', {
        state,
      });
    }
  };

  const handleProductTypeChange = useCallback((type: ProductType) => {
    setProductType(type);
  }, []);

  const handleCalculatorClose = useCallback(() => {
    setConfirmationShown(true);
  }, []);

  const handleCancellationSurveySubmit = (cancellationReason: string) => {
    trackCalculatorStateUpdate(cancellationReason);
    navigate('/applications', { replace: true });
  };

  const handleTimeInSuccess = () => {
    const values = loanCalculatorValuesRef.current[
      productType
    ] as LoanCalculatorFormValues;
    const state: ClientInitiationNavigationState = {
      loanCalculator: values,
      productType,
      forcedOfferAppliedId: initialOfferRef.current?.offer.id,
      calculatorSessionId: sessionIdRef.current,
      isOfferCancelled: !values.offer && isOfferCancelled,
    };

    if (preApprovedApplication) {
      navigate(
        `/self-application-agent/${preApprovedApplication.applicationId}`,
        {
          state,
        }
      );
    } else {
      navigate('/client-initiation', {
        state,
      });
    }
  };

  if (cancelSurveyShown) {
    return (
      <Screen header={<Header onClose={() => setCancelSurveyShown(false)} />}>
        <ConfirmationSurvey onSubmit={handleCancellationSurveySubmit} />
      </Screen>
    );
  }

  if (isAttendanceLogInProgress) {
    return (
      <LogAttendance
        status={nonNullableValue(shouldCheckAttendance.nextStatus)}
        onSuccess={handleTimeInSuccess}
        onCancel={() => setAttendanceLogInProgress(false)}
      />
    );
  }

  return (
    <>
      <Screen header={<Header onClose={handleCalculatorClose} />}>
        <div className={styles.container}>
          <ScreenTitle title="Calculate a&nbsp;new loan" />
          <WithProductType
            type={productType}
            onProductTypeChange={handleProductTypeChange}
          >
            <LoanCalculator
              isPreApproved={!!preApprovedApplication}
              key={productType}
              type={productType}
              initialOffer={initialOfferRef.current}
              initialValue={loanCalculatorValuesRef.current[productType]}
              onFieldCompleted={handleUpdate}
              onSubmit={handleSubmit}
              onCancelOfferClicked={() => setIsOfferCancelled(true)}
            />
          </WithProductType>
        </div>
      </Screen>
      <ConfirmationPopup
        onClose={() => {
          setConfirmationShown(false);
          blocker.reset?.();
        }}
        isOpened={confirmationShown || blocker.state === 'blocked'}
        onConfirm={() => {
          blocker.reset?.();
          setConfirmationShown(false);
          setCancelSurveyShown(true);
        }}
      />
      {forcedOfferShown && (
        <ForceOfferBanner
          title={initialOfferRef.current?.offer.posBannerTemplateParams.title}
          subtitle={
            initialOfferRef.current?.offer.posBannerTemplateParams.subtitle
          }
          onClose={() => setForcedOfferShown(false)}
        />
      )}

      <Sheet
        mode="normal"
        modes={{
          collapsed: false,
          normal: {
            minHeight: 240,
            maxHeight: 440,
          },
          expanded: false,
        }}
        opened={isAttendanceSheetOpened}
        onChange={() => {
          // do nothing
        }}
        onClose={() => setAttendanceSheetOpened(false)}
      >
        <AttendanceWarning
          onContinue={() => {
            setAttendanceLogInProgress(true);
            setAttendanceSheetOpened(false);
          }}
        />
      </Sheet>
    </>
  );
};
/* eslint-enable max-statements */
