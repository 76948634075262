import { useState } from 'react';

import { environment } from '~app/environment';

import { ApplicationProcessStatusDetails } from '~features/application-process-status';
import { useShouldForceAttendanceLog } from '~features/check-attendance';
import { LogAttendance } from '~features/log-attendance';

import type { ApplicationDetails as Details } from '~entities/application';
import { canCancel, isAgreementCreated } from '~entities/application';
import { AttendanceWarning } from '~entities/attendance';

import { nonNullableValue } from '~shared/types/non-nullable-value';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';
import Sheet from '@breeze-platform-ui/sheet';

import { ApplicationCancelPopup } from './application-cancel-popup';
import { OrderItems } from './order-items';
import { StoreInfo } from './store-info';
import { UserInfo } from './user-info';

type Props = {
  application: Details;
};

export const ApplicationDetails = ({ application }: Props) => {
  const [isCancelPopupOpened, setIsCancelPopupOpened] = useState(false);
  const hasCancelApplicationFeature =
    environment.APPLICATION_CANCEL_FEATURE === 'ON';
  const agreementId =
    (isAgreementCreated(application) && application.agreementId) || undefined;
  const shouldCheckAttendance = useShouldForceAttendanceLog();

  const [isAttendanceLogInProgress, setAttendanceLogInProgress] =
    useState(false);
  const [isAttendanceSheetOpened, setAttendanceSheetOpened] = useState(false);

  if (isAttendanceLogInProgress) {
    return (
      <LogAttendance
        status={nonNullableValue(shouldCheckAttendance.nextStatus)}
        onSuccess={() => {
          setAttendanceLogInProgress(false);
          setIsCancelPopupOpened(true);
        }}
        onCancel={() => setAttendanceLogInProgress(false)}
      />
    );
  }

  const handleCancelApplication = () => {
    if (shouldCheckAttendance.shouldForce) {
      setAttendanceSheetOpened(true);
      return;
    }

    setIsCancelPopupOpened(true);
  };

  return (
    <>
      <Col gaps="xl" alignCross="stretch">
        <UserInfo
          name={application.person.fullName || ''}
          phoneNumber={application.person.mobilePhone}
          agreementId={agreementId}
        />
        <ApplicationProcessStatusDetails applicationDetails={application} />
        {application.orderItems && (
          <OrderItems items={application.orderItems} />
        )}
        <StoreInfo posId={application.posId} />
        {hasCancelApplicationFeature && canCancel(application) && (
          <Button
            wide
            size="m"
            theme="secondary"
            onClick={handleCancelApplication}
          >
            Cancel application
          </Button>
        )}
        {hasCancelApplicationFeature && (
          <ApplicationCancelPopup
            applicationId={application.id}
            isOpened={isCancelPopupOpened}
            onClose={() => setIsCancelPopupOpened(false)}
          />
        )}
      </Col>

      <Sheet
        dataQaType="attendance-warning-sheet"
        mode="normal"
        modes={{
          collapsed: false,
          normal: {
            minHeight: 240,
            maxHeight: 440,
          },
          expanded: false,
        }}
        opened={isAttendanceSheetOpened}
        onChange={() => {
          // do nothing
        }}
        onClose={() => setAttendanceSheetOpened(false)}
      >
        <AttendanceWarning
          onContinue={() => {
            setAttendanceLogInProgress(true);
            setAttendanceSheetOpened(false);
          }}
        />
      </Sheet>
    </>
  );
};
