import type {
  GiftPayment,
  GiftPaymentRatesByTerm,
} from '~entities/gift-payment';
import { calculateGiftPayment } from '~entities/gift-payment';

import { calculateMonthlyPayment } from './loan-math';

import type {
  ProductParams,
  PaymentPlan,
  ApprovedProductOption,
  ApprovedPaymentPlan,
} from '../product';

export function getPaymentPlans(
  loanAmount: number,
  {
    minMonthlyInterestRate,
    maxMonthlyInterestRate,
    minOriginationFeeFlat,
    maxOriginationFeeFlat,
    minOriginationFeeRate,
    maxOriginationFeeRate,
    termsInMonths,
  }: ProductParams,
  giftPaymentRatesByTerm?: Record<number, number>
): PaymentPlan[] {
  return termsInMonths.map((term) => {
    const minMonthlyPayment = calculateMonthlyPayment(
      loanAmount,
      +term,
      minMonthlyInterestRate,
      minOriginationFeeRate,
      minOriginationFeeFlat
    );
    const maxMonthlyPayment = calculateMonthlyPayment(
      loanAmount,
      +term,
      maxMonthlyInterestRate,
      maxOriginationFeeRate,
      maxOriginationFeeFlat
    );

    const giftPaymentRate = giftPaymentRatesByTerm?.[term];
    const giftPayment: PaymentPlan['giftPayment'] = giftPaymentRate
      ? {
          ratePercent: giftPaymentRate,
          amount: calculateGiftPayment({ rate: giftPaymentRate, loanAmount }),
        }
      : undefined;

    return {
      term,
      minMonthlyPayment,
      maxMonthlyPayment,
      giftPayment,
    };
  });
}

export function getApprovedPaymentPlans(
  loanAmount: number,
  options: ApprovedProductOption[],
  giftPaymentRatesByTerm?: GiftPaymentRatesByTerm
): ApprovedPaymentPlan[] {
  return options.map(
    ({
      id,
      loanTermInMonths,
      monthlyInterestRate,
      originationFeeFlat,
      originationFeeRate,
    }) => {
      const giftPaymentRate = giftPaymentRatesByTerm?.[loanTermInMonths];
      const giftPayment = giftPaymentRate
        ? ({
            ratePercent: giftPaymentRate,
            amount: calculateGiftPayment({ rate: giftPaymentRate, loanAmount }),
          } as Omit<GiftPayment, 'termInMonths'>)
        : undefined;

      return {
        approvedProductOptionId: id,
        term: loanTermInMonths,
        monthlyPayment: calculateMonthlyPayment(
          loanAmount,
          loanTermInMonths,
          monthlyInterestRate,
          originationFeeRate,
          originationFeeFlat
        ),
        giftPayment,
      };
    }
  );
}
