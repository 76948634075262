import { Controller, useForm } from 'react-hook-form';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { LoadingButton } from '@breeze-platform-ui/button';
import { Box } from '@breeze-platform-ui/layout';
import { Textarea } from '@breeze/rhf-adapters';

type Props = {
  initialComment?: string;
  isLoading?: boolean;
  onSave: (comment?: string) => void;
};
export const UpdateSocialLeadComment: React.FC<Props> = ({
  initialComment,
  isLoading,
  onSave,
}) => {
  const { control, handleSubmit } = useForm<{ comment: string }>({
    mode: 'all',
    defaultValues: { comment: initialComment },
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form onSubmit={handleSubmit((values) => onSave(values.comment))}>
      <Controller
        name="comment"
        control={control}
        rules={{
          validate: combineValidators(
            validators.latinText(),
            validators.maxLength({ maxLength: 500 })
          ),
        }}
        render={(props) => (
          <Box padding="8px 0 24px 0">
            <Textarea {...props} rows={4} />
          </Box>
        )}
      />
      <LoadingButton type="submit" size="m" wide loading={isLoading}>
        Save
      </LoadingButton>
    </form>
  );
};
