import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { NewSocialLead } from '~entities/social-lead';

import { combineValidators } from '~shared/lib/combine-validators';
import { validators } from '~shared/validators';

import { Box, Col } from '@breeze-platform-ui/layout';
import {
  Textarea,
  AttachFile,
  InputDate,
  Input,
  Select,
  InputPhone,
  InputCapitalize,
} from '@breeze/rhf-adapters';

import styles from './create-online-customer.module.css';

import { socialPlatformOptions } from '../../lib';

export type OnlineCustomerFormValues = NewSocialLead;

export const CreateOnlineCustomer: React.FC = () => {
  const methods = useFormContext<OnlineCustomerFormValues>();

  const shouldShowOtherPlarform =
    methods.watch('socialMediaPlatform') === 'OTHER';

  return (
    <>
      <div className={styles.header}>
        <h1 className={styles.title}>Online customer form</h1>
        <p className={styles.footnote}>
          Fill&nbsp;in the&nbsp;information about the&nbsp;customer
        </p>
      </div>

      <Box margin="0 0 4px 0">
        <Controller
          control={methods.control}
          name="firstName"
          rules={{
            validate: combineValidators(
              validators.required({
                text: 'Enter customer’s first name',
              }),
              validators.maxLength({ maxLength: 300 }),
              validators.filipinoName()
            ),
          }}
          render={(props) => <InputCapitalize {...props} label="First name" />}
        />
        <Controller
          control={methods.control}
          name="lastName"
          rules={{
            validate: combineValidators(
              validators.required({ text: 'Enter customer’s last name' }),
              validators.maxLength({ maxLength: 300 }),
              validators.filipinoName()
            ),
          }}
          render={(props) => <InputCapitalize {...props} label="Last name" />}
        />
        <Controller
          control={methods.control}
          name="phone"
          rules={{
            validate: combineValidators(
              validators.phoneLength(),
              validators.mobilePhoneFormat()
            ),
          }}
          render={(props) => (
            <InputPhone {...props} label="Mobile number (optional)" />
          )}
        />
        <Controller
          control={methods.control}
          name="socialMediaPlatform"
          rules={{
            validate: combineValidators(
              validators.required({ text: 'Select an option' })
            ),
          }}
          render={(props) => (
            <Select
              {...props}
              label="Name of the platform"
              options={socialPlatformOptions}
            />
          )}
        />
        {shouldShowOtherPlarform && (
          <Controller
            control={methods.control}
            shouldUnregister
            name="socialMediaPlatformOther"
            rules={{
              validate: combineValidators(
                validators.required({
                  text: 'Enter the name of the platform',
                }),
                validators.maxLength({ maxLength: 150 })
              ),
            }}
            render={(props) => (
              <Input {...props} label="Name of the platform" />
            )}
          />
        )}
        <Controller
          control={methods.control}
          name="conversationDetails.dateOfConversation"
          rules={{
            validate: combineValidators(
              validators.required({
                text: 'Enter the date of the conversation',
              }),
              validators.isDate(),
              validators.maxDate({
                maxDate: new Date(),
                message: 'Date of the conversation should be in the past',
              })
            ),
          }}
          render={(props) => (
            <InputDate
              {...props}
              label="Date of conversation"
              field={{
                ...props.field,
                value: props.field.value || '',
                onChange: (_event, params) => {
                  props.field.onChange(params?.maskedValue ?? '');
                },
              }}
            />
          )}
        />

        <Controller
          control={methods.control}
          name="profilePage"
          rules={{
            validate: combineValidators(
              validators.maxLength({ maxLength: 150 })
            ),
          }}
          render={(props) => (
            <Input
              {...props}
              cleanable
              label="Link to customer’s profile (optional)"
            />
          )}
        />

        <Controller
          control={methods.control}
          name="conversationDetails.plannedDateOfVisit"
          rules={{
            validate: combineValidators(
              validators.isDate(),
              validators.minDate({
                minDate: new Date(),
                message: 'Planned date of visit should be in the future',
              })
            ),
          }}
          render={(props) => (
            <InputDate
              {...props}
              label="Planned date of visit (optional)"
              withValidityMark={false}
              cleanable
              field={{
                ...props.field,
                value: props.field.value || '',
                onChange: (_event, params) => {
                  props.field.onChange(params?.maskedValue ?? '');
                },
              }}
            />
          )}
        />
      </Box>

      <Box padding="5px 0" margin="0 0 4px 0">
        <p className={styles.sectionTitle}>
          Attach screenshots of&nbsp;the&nbsp;conversation
        </p>
        <p className={styles.footnote}>
          Customer’s name and mobile number must be clearly visible. You can
          attach up&nbsp;to 5 screenshots
        </p>
        <Controller
          control={methods.control}
          name="screenshots"
          rules={{
            validate: combineValidators(
              validators.fileRequired({
                message: 'Please, attach the screenshots',
              }),
              validators.maxFiles({
                max: 5,
                message: 'You can attach up to 5 screenshots',
              })
            ),
          }}
          render={(fieldProps) => (
            <AttachFile
              {...fieldProps}
              uploadFile={() => Promise.resolve()}
              preview
              accept="image/*"
              labels={{
                common: 'Choose files',
                desktop: ' ',
                dragging: ' ',
              }}
            />
          )}
        />
      </Box>

      <Col padding="5px 0" alignCross="stretch" gaps={8}>
        <p className={styles.sectionTitle}>Notes or comments</p>
        <Controller
          control={methods.control}
          name="conversationDetails.comment"
          rules={{
            validate: combineValidators(
              validators.latinText(),
              validators.maxLength({ maxLength: 500 })
            ),
          }}
          render={(props) => <Textarea {...props} rows={3} />}
        />
      </Col>
    </>
  );
};
