import { environment } from '~app/environment';

import type { RequestParams, ScpClient } from '~shared/scp-client';
import { webRequestClient } from '~shared/scp-client';

import type { ConversationDetails, MarketingLead } from '../marketing-lead';

export type UpdateConversationDetailsPayload = {
  id: string;
  conversationDetails: ConversationDetails;
};

interface LeadApi {
  getLeads(): Promise<MarketingLead[]>;
  updateConversationDetails(
    payload: UpdateConversationDetailsPayload
  ): Promise<void>;
}

export class GatewayLeadApi implements LeadApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: { host: string }) {
    this.scpClient = scpClient;
    this.host = options.host;
  }

  getLeads(): Promise<MarketingLead[]> {
    return this.makeRequest<MarketingLead[]>({
      method: 'get-list',
    });
  }

  updateConversationDetails(
    payload: UpdateConversationDetailsPayload
  ): Promise<void> {
    return this.makeRequest({
      method: 'update-conversation-details',
      payload,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v1',
        domain: 'marketing',
        service: 'leads/web',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayMarketingLeadsApi = new GatewayLeadApi(webRequestClient, {
  host: environment.API_HOST,
});
