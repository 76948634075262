import { config } from '~app/feature-configs/merchant-offers';

import type { SearchOfferResponse } from '../api';

const { merchantIdsWithOffer, sourceProductId, targetProductId } = config;

export const handleMerchantOffer = (
  offerResponse: SearchOfferResponse,
  merchantId = ''
): SearchOfferResponse => {
  if (
    offerResponse.status === 'SUCCESS' &&
    offerResponse.posProductId === sourceProductId &&
    !merchantIdsWithOffer.includes(merchantId)
  ) {
    return {
      ...offerResponse,
      posProductId: targetProductId,
      posBannerTemplateParams: {
        title: '0DP, 30k FA',
        subtitle: '0% down payment, 30k financed amount',
      },
    };
  }

  return offerResponse;
};
