import { Skeleton } from '~shared/ui/skeleton';

import styles from './skeleton.module.css';

export const LeadsSkeleton = () => {
  return (
    <div className={styles.list}>
      {[...Array(7)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={styles.item}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Skeleton className={styles.name} />
              {index % 3 === 2 && <Skeleton className={styles.tag} />}
            </div>
            <Skeleton className={styles.note} />
          </div>
          <Skeleton className={styles.action} />
        </div>
      ))}
    </div>
  );
};
