import errorIcon from '~shared/images/error.svg';
import { Warning } from '~shared/ui/warning';

import Button from '@breeze-platform-ui/button';
import { Box } from '@breeze-platform-ui/layout';

export const Error = ({ onBack }: { onBack: () => void }) => (
  <Warning
    icon={errorIcon}
    title="Couldn't load the list of&nbsp;your leads"
    description="Please, try again later"
    footerContent={
      <Box margin="0 auto">
        <Button wide onClick={onBack}>
          Go back
        </Button>
      </Box>
    }
  />
);
