import { getSocialPlatformText } from '~widgets/social-lead-details/lib/get-social-platform-text';

import type { SocialLead } from '~entities/social-lead';

import { plural } from '~shared/lib/plural/plural';

import { format } from 'date-fns';

import styles from './conversation-details.module.css';

export const ConversationDetails: React.FC<{ lead: SocialLead }> = ({
  lead,
}) => {
  const formattedLink =
    lead.profilePage && lead.profilePage.startsWith('http')
      ? lead.profilePage
      : `//${lead.profilePage}`;
  return (
    <>
      <div className={styles.section}>
        <p className={styles.sectionTitle}>Social media platform</p>
        <p className={styles.sectionFootnote}>{getSocialPlatformText(lead)}</p>
      </div>

      <div className={styles.section}>
        <p className={styles.sectionTitle}>Date of conversation</p>
        <p className={styles.sectionFootnote}>
          {format(lead.conversationDetails.dateOfConversation, 'MM/dd/yyyy')}
        </p>
      </div>

      {lead.profilePage && (
        <div className={styles.section}>
          <p className={styles.sectionTitle}>Link to customer’s profile</p>
          <a
            className={`${styles.sectionFootnote} ${styles.link}`}
            href={formattedLink}
            target="_blank"
            rel="noreferrer"
            // rel="noreferrer"
          >
            {lead.profilePage}
          </a>
        </div>
      )}

      {lead.conversationDetails.plannedDateOfVisit && (
        <div className={styles.section}>
          <p className={styles.sectionTitle}>Planned date of visit</p>
          <p className={styles.sectionFootnote}>
            {format(lead.conversationDetails.plannedDateOfVisit, 'MM/dd/yyyy')}
          </p>
        </div>
      )}

      <div className={styles.section}>
        <p className={styles.sectionTitle}>Attached screenshots</p>
        <p className={styles.sectionFootnote}>
          {lead.conversationDetails.screenshotUrls.length}{' '}
          {plural(
            lead.conversationDetails.screenshotUrls.length,
            'screenshot',
            'screenshots'
          )}{' '}
          attached
        </p>
      </div>
    </>
  );
};
