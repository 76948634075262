import type { CustomerFeedback } from '~entities/marketing-lead';

import type { AbstractOption } from '~shared/types/abstract-option';

export const feedbackOptions: AbstractOption<CustomerFeedback>[] = [
  {
    title: 'Interested',
    value: 'INTERESTED',
  },
  {
    title: 'Not interested',
    value: 'NOT_INTERESTED',
  },
  {
    title: 'Applied but rejected',
    value: 'APPLIED_BUT_REJECTED',
  },
  {
    title: 'Utilized',
    value: 'UTILIZED',
  },
  {
    title: 'Undecided',
    value: 'UNDECIDED',
  },
  {
    title: 'Not answering',
    value: 'HAVENT_ANSWERED',
  },
  {
    title: 'Cannot be reached/Wrong number',
    value: 'CANNOT_BE_REACHED_WRONG_NUMBER',
  },
];
