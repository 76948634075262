import { environment } from '~app/environment';

import type { RequestParams, ScpClient } from '~shared/scp-client';
import { webRequestClient } from '~shared/scp-client';

import type {
  GetGiftPaymentsParams,
  GiftPaymentsApi,
  GiftPaymentSettings,
} from './gift-payments-api';

export class GatewayGiftPaymentsApi implements GiftPaymentsApi {
  scpClient: ScpClient;
  host: string;

  constructor(scpClient: ScpClient, options: { host: string }) {
    this.scpClient = scpClient;

    this.host = options.host;
  }

  async getGiftPaymentsSettings(payload: GetGiftPaymentsParams) {
    return this.makeRequest<GiftPaymentSettings[]>({
      method: 'settings/get',
      payload,
    });
  }

  private makeRequest<T>(params: Partial<RequestParams> & { method: string }) {
    return this.scpClient
      .call<T>({
        host: this.host,
        version: 'v2',
        domain: 'origination',
        service: 'applications/pos-loan/gift-payment',
        withCredentials: true,
        withLeadingDomain: true,
        ...params,
      })
      .then((response) => response.payload);
  }
}

export const gatewayGiftPaymentsApi = new GatewayGiftPaymentsApi(
  webRequestClient,
  { host: environment.API_HOST }
);
