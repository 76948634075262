import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ContactLead } from '~features/contact-lead/contact-lead';
import { UpdateMarketingLead } from '~features/update-marketing-lead';

import type {
  ConversationDetails,
  MarketingLead,
} from '~entities/marketing-lead';
import { useUpdateConversationDetails } from '~entities/marketing-lead';

import { NavBackButton } from '~shared/ui/nav-back-button';
import { PopupCloseButton } from '~shared/ui/popup-close-button';
import { Screen } from '~shared/ui/screen';

import isEmpty from '@tinkoff/utils/is/empty';
import filter from '@tinkoff/utils/object/filter';

import { LoadingButton } from '@breeze-platform-ui/button';
import { ButtonLink } from '@breeze-platform-ui/button';
import Sheet from '@breeze-platform-ui/sheet';
import { useNotifications } from '@pfa/front-notifications';

import styles from './lead-details.module.css';

import {
  formatConversationDetails,
  recoverConversationDetails,
} from '../../lib/conversation-details-converter';
import { LeadConversationDetails } from '../lead-conversation-details/lead-conversation-details';

export const LeadDetails: React.FC<{
  lead: MarketingLead;
  onBack: () => void;
}> = ({ lead, onBack }) => {
  const methods = useForm<ConversationDetails>({
    defaultValues: recoverConversationDetails(lead.conversationDetails),
    mode: 'all',
  });
  const hasInitialConversationDetails = !isEmpty(
    lead.conversationDetails && filter((v) => !!v, lead.conversationDetails)
  );
  const formValue = methods.watch();
  const canSaveConversationDetails =
    !hasInitialConversationDetails && !isEmpty(filter((v) => !!v, formValue));

  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);
  const updateConversationDetailsMutation = useUpdateConversationDetails();
  const notifications = useNotifications();

  const onSubmit = (values: ConversationDetails) => {
    updateConversationDetailsMutation.mutate(
      {
        id: lead.id,
        conversationDetails: formatConversationDetails(values),
      },
      {
        onSuccess: () => {
          setIsEditPopupOpened(false);
        },
        onError: () => {
          notifications.error(
            "Couldn't update conversation details. Please, try again later"
          );
        },
      }
    );
  };

  const handleCancelEditConversationDetails = () => {
    setIsEditPopupOpened(false);
    methods.reset(recoverConversationDetails(lead.conversationDetails));
  };

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Screen
          header={<NavBackButton onClick={onBack} />}
          footer={
            canSaveConversationDetails && (
              <LoadingButton
                type="submit"
                wide
                size="m"
                loading={updateConversationDetailsMutation.isLoading}
              >
                Save
              </LoadingButton>
            )
          }
        >
          <div className={styles.container}>
            <ContactLead {...lead} />

            {lead.note && (
              <section className={styles.section}>
                <h4 className={styles.sectionTitle}>Lead status</h4>
                <p className={styles.sectionFootnote}>{lead.note}</p>
              </section>
            )}

            <section className={styles.section}>
              <div className={styles.conversationDetailsHeader}>
                <h4 className={styles.sectionTitle}>Conversation details</h4>
                {hasInitialConversationDetails && (
                  <ButtonLink
                    disableHorizontalPadding
                    disableVerticalPadding
                    size="m"
                    onClick={() => setIsEditPopupOpened(true)}
                  >
                    Edit
                  </ButtonLink>
                )}
              </div>
              {hasInitialConversationDetails && (
                <LeadConversationDetails {...lead.conversationDetails} />
              )}
              {!hasInitialConversationDetails && (
                <FormProvider {...methods}>
                  <UpdateMarketingLead />
                </FormProvider>
              )}
            </section>
          </div>
        </Screen>
      </form>
      <Sheet
        mode="normal"
        modes={{
          collapsed: false,
          normal: {
            minHeight: 240,
            maxHeight: 492,
          },
          expanded: false,
        }}
        opened={isEditPopupOpened}
        onChange={() => {
          // do nothing
        }}
        onClose={handleCancelEditConversationDetails}
      >
        <div className={styles.sheetHeader}>
          <h4 className={styles.sectionTitle}>Edit conversation details</h4>
          <PopupCloseButton onClick={handleCancelEditConversationDetails} />
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <UpdateMarketingLead />

            <LoadingButton
              type="submit"
              size="m"
              wide
              loading={updateConversationDetailsMutation.isLoading}
            >
              Save
            </LoadingButton>
          </FormProvider>
        </form>
      </Sheet>
    </>
  );
};
