import {
  type AgentNewsInfo,
  isItemImportant,
  shouldMarkUnread,
} from '~entities/agent-news';

import { Int16AttentionFilled } from '@breeze-platform-ui/iconsPack';
import { Row } from '@breeze-platform-ui/layout';
import cn from 'classnames';
import { format, isToday } from 'date-fns';

import styles from './agent-news-list.module.css';

type Props = {
  agentNewsList: AgentNewsInfo[];
  onItemClick: (id: string) => void;
};

export const AgentNewsList = ({ agentNewsList, onItemClick }: Props) => {
  return (
    <ul className={styles.list}>
      {agentNewsList.map((item) => {
        const isMarkedUnread = shouldMarkUnread(item);
        const shouldShowAttentionMark = isMarkedUnread && isItemImportant(item);
        const dateObj = new Date(item.publishedAt);
        const formattedDate = format(
          dateObj,
          isToday(dateObj) ? 'h:mm a' : 'MMMM d'
        );
        return (
          <li key={item.id}>
            <button
              type="button"
              onClick={() => onItemClick(item.id)}
              className={styles.card}
            >
              <div
                className={cn(styles.title, {
                  [styles.unread]: isMarkedUnread,
                })}
              >
                {item.title}
              </div>
              <Row
                alignCross="center"
                alignMain="between"
                gaps={4}
                className={styles.info}
              >
                {shouldShowAttentionMark ? (
                  <Int16AttentionFilled theme="red" />
                ) : (
                  <div />
                )}
                <div
                  className={cn(styles.date, {
                    [styles.unread]: isMarkedUnread,
                  })}
                >
                  {formattedDate}
                </div>
              </Row>
            </button>
          </li>
        );
      })}
    </ul>
  );
};
