import type { MarketingLead } from '~entities/marketing-lead';
import { MarketingLeadItem } from '~entities/marketing-lead';

import Clickable from '@breeze-platform-ui/clickable';
import { Int16ArrowRight } from '@breeze-platform-ui/iconsPack';

import styles from './marketing-leads-list.module.css';

import { EmptyList } from '../empty/empty-list';
import { LeadsSkeleton } from '../skeleton/skeleton';

export const MarketingLeadsList: React.FC<{
  leads?: MarketingLead[];
  isLoading?: boolean;
  onLeadClick: (id: string) => void;
}> = ({ leads, isLoading, onLeadClick }) => {
  if (isLoading) {
    return <LeadsSkeleton />;
  }

  if (leads?.length) {
    return (
      <div className={styles.list}>
        {leads.map((lead) => (
          <Clickable
            key={lead.id}
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
            onClick={() => onLeadClick(lead.id)}
          >
            <div className={styles.itemContainer}>
              <div className={styles.content}>
                <MarketingLeadItem {...lead} />
              </div>
              <div className={styles.icon}>
                <Int16ArrowRight theme={{ color: '#00000029' }} />
              </div>
            </div>
          </Clickable>
        ))}
      </div>
    );
  }

  return <EmptyList />;
};
