import type { PropsWithChildren } from 'react';
import { useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppLoadingPage } from '~widgets/app-loading-page';

import { useAttendanceStatus } from '~entities/attendance';
import { isAuthorizedAtom, isEmployeeRoleAllowedAtom } from '~entities/auth';

import { useAtomValue } from 'jotai';

export const ExplainMissedCheckoutGuardHOC: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const hasAccess = useAtomValue(isEmployeeRoleAllowedAtom);
  const isAttendanceRequestEnabled = !!hasAccess && !!isAuthorized;
  const attendanceStatusQuery = useAttendanceStatus({
    enabled: isAttendanceRequestEnabled,
  });
  const navigate = useNavigate();
  const initialPathNameRef = useRef<string>(useLocation().pathname);

  useEffect(() => {
    if (
      attendanceStatusQuery.data &&
      attendanceStatusQuery.data.forced &&
      !attendanceStatusQuery.data.explanatoryNoteExists
    ) {
      navigate(`/missed-checkout?redirectUrl=${initialPathNameRef.current}`);
    }
  }, [attendanceStatusQuery.data, navigate]);

  if (isAttendanceRequestEnabled && attendanceStatusQuery.isLoading) {
    return <AppLoadingPage />;
  }

  return <>{children}</>;
};
