import { useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { PosLoan } from '~entities/application';
import type {
  GiftPayment,
  GiftPaymentRatesByTerm,
} from '~entities/gift-payment';
import {
  getMinDownPayment,
  validateDownpaymentRange,
  getMaxDownPayment,
  getRangeMessage,
  type ApprovedProductOption,
} from '~entities/product';
import { getApprovedPaymentPlans } from '~entities/product';

import { phMoneyProps } from '~shared/constants/ph-money-props';
import { useOnMountEffect } from '~shared/hooks';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { FormHeader } from '~shared/ui/form-header';
import { Screen } from '~shared/ui/screen';
import { ScreenTitle } from '~shared/ui/screen-title';
import { SectionTitle } from '~shared/ui/section-title';

import { LoadingButton } from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';
import Money from '@breeze-platform-ui/money/Money';
import { RadioGroup, InputMoney } from '@breeze/rhf-adapters';

import styles from './final-calculator-form.module.css';
import { TermsHint } from './terms-hint';

import {
  getPaymentPlanOptions,
  getProductOptionsMap,
  DOWNPAYMENT_LABEL,
  DOWNPAYMENT_REQUIRED_ERROR,
} from '../lib';

const loanMoneyProps = { ...phMoneyProps, precision: 2 };

type FinalLoanFormValues = {
  downPayment: number;
  approvedProductOptionId: string;
  giftPayment?: GiftPayment;
};

export type FinalCalculatorValues = FinalLoanFormValues & {
  loanAmount: number;
};
type Props = {
  requestedLoan: PosLoan;
  approvedProductOptions: ApprovedProductOption[];
  giftPaymentRatesByTerm?: GiftPaymentRatesByTerm;
  isLoading: boolean;
  onSubmit: (values: FinalCalculatorValues) => void;
  onClose: () => void;
};

export const FinalLoanCalculatorForm = ({
  requestedLoan,
  approvedProductOptions,
  giftPaymentRatesByTerm,
  onSubmit,
  onClose,
  isLoading,
}: Props) => {
  const productOptionsMap = useMemo(
    () => getProductOptionsMap(approvedProductOptions),
    [approvedProductOptions]
  );

  const totalPrice = useMemo(
    () =>
      +nonNullableValue(requestedLoan.desiredLoanAmount) +
      nonNullableValue(requestedLoan.downPayment),
    [requestedLoan]
  );

  const [loanAmount, setLoanAmount] = useState(
    +nonNullableValue(requestedLoan.desiredLoanAmount)
  );

  const {
    control,
    watch,
    trigger,
    handleSubmit,
    formState,
    getValues,
    setValue,
  } = useForm<FinalLoanFormValues>({
    mode: 'all',
    defaultValues: {
      downPayment: requestedLoan.downPayment,
      approvedProductOptionId:
        approvedProductOptions.find(
          ({ loanTermInMonths }) =>
            loanTermInMonths === requestedLoan.desiredLoanTerm
        )?.id || approvedProductOptions[0].id,
    },
  });

  const productId = watch('approvedProductOptionId');
  const downPayment = watch('downPayment');

  useEffect(() => {
    trigger('downPayment');
  }, [productId, trigger]);

  const selectedOption = nonNullableValue(productOptionsMap[productId]);

  const minDownpayment = getMinDownPayment(totalPrice, {
    maxLoanAmount: selectedOption.maxLoanAmount,
    minDownPaymentPercent: selectedOption.minDownPaymentPercent,
  });

  const maxDownPayment = getMaxDownPayment(totalPrice, {
    minLoanAmount: selectedOption.minLoanAmount,
    maxDownPaymentPercent: selectedOption.maxDownPaymentPercent,
  });

  const downPaymentMessage = getRangeMessage(minDownpayment, maxDownPayment);

  const paymentPlans = useMemo(
    () =>
      getApprovedPaymentPlans(
        loanAmount,
        approvedProductOptions,
        giftPaymentRatesByTerm
      ),
    [loanAmount, approvedProductOptions, giftPaymentRatesByTerm]
  );

  useOnMountEffect(() => {
    const currentDownPayment = getValues('downPayment');
    let dp = currentDownPayment;

    if (!currentDownPayment || currentDownPayment < minDownpayment) {
      dp = minDownpayment;
    }
    if (currentDownPayment > maxDownPayment) {
      dp = maxDownPayment;
    }
    setValue('downPayment', dp, { shouldValidate: true });
  });

  useEffect(() => {
    if (!formState.isValidating && !formState.errors.downPayment) {
      setLoanAmount(Math.round((totalPrice - downPayment) * 100) / 100);
    }
  }, [downPayment, totalPrice, formState]);

  const handleFinalCalculatorSubmit = (values: FinalLoanFormValues) => {
    const selectedPaymentPlan = paymentPlans.find(
      (plan) => plan.approvedProductOptionId === values.approvedProductOptionId
    );
    const giftPayment = selectedPaymentPlan?.giftPayment;

    onSubmit({
      ...values,
      loanAmount,
      giftPayment: giftPayment && {
        ...giftPayment,
        termInMonths: selectedPaymentPlan.term,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFinalCalculatorSubmit)}>
      <Screen
        header={<FormHeader onClick={onClose} />}
        footer={
          <LoadingButton
            type="submit"
            wide
            loading={isLoading}
            disabled={isLoading}
          >
            Submit
          </LoadingButton>
        }
      >
        <ScreenTitle title="After careful review, we can offer the&nbsp;following conditions" />
        <Controller
          name="downPayment"
          control={control}
          rules={{
            required: DOWNPAYMENT_REQUIRED_ERROR,
            validate: (value) =>
              // react-hook-form doesn't accept ReactNode as error, but FormRow does -> have to use any
              validateDownpaymentRange(+value, totalPrice, {
                maxLoanAmount: selectedOption?.maxLoanAmount,
                minLoanAmount: selectedOption.minLoanAmount,
                minDownPaymentPercent: selectedOption.minDownPaymentPercent,
                maxDownPaymentPercent: selectedOption.maxDownPaymentPercent,
              }) as any,
          }}
          render={(props) => (
            <InputMoney
              {...props}
              {...phMoneyProps}
              cleanable
              currencyInValue
              withValidityMark={false}
              label={DOWNPAYMENT_LABEL}
              emptyValue={minDownpayment}
              message={!props.fieldState.error && downPaymentMessage}
            />
          )}
        />
        <SectionTitle title="Choose your payment period" margin="0 0 12px" />
        <Controller
          name="approvedProductOptionId"
          control={control}
          render={(props) => (
            <RadioGroup
              {...props}
              vertical
              wide
              options={getPaymentPlanOptions(paymentPlans) as any}
            />
          )}
        />
        <Col className={styles.footnote} gaps={10}>
          <TermsHint {...selectedOption} />
          <span>
            Loan amount:&nbsp;
            <Money {...loanMoneyProps} value={loanAmount} />
          </span>
        </Col>
      </Screen>
    </form>
  );
};
