import { useNavigate, useParams } from 'react-router-dom';

import { LeadDetails } from '~widgets/marketing-lead-details';

import { useGetMarketingLeads } from '~entities/marketing-lead';

import errorImg from '~shared/images/error.svg';
import { Loader } from '~shared/ui/loader';
import { NavBackButton } from '~shared/ui/nav-back-button';
import { Screen } from '~shared/ui/screen';
import { Warning } from '~shared/ui/warning';

import Button from '@breeze-platform-ui/button';

export const MarketingLeadDetailsPage = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const leadQuery = useGetMarketingLeads({
    select: (leads) => (id ? leads.find((lead) => lead.id === id) : undefined),
  });

  const handleGoBack = () => navigate('/skyro-leads');

  if (leadQuery.isSuccess) {
    return leadQuery.data ? (
      <LeadDetails lead={leadQuery.data} onBack={handleGoBack} />
    ) : (
      <Screen header={<NavBackButton onClick={handleGoBack} />}>
        <Warning
          title="Lead is not found"
          icon={errorImg}
          footerContent={
            <Button size="m" wide onClick={handleGoBack}>
              Back to Skyro leads
            </Button>
          }
        />
      </Screen>
    );
  }

  return (
    <Screen header={<NavBackButton onClick={handleGoBack} />}>
      <Loader centered />
    </Screen>
  );
};
