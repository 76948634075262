import { environment } from '../environment';

export const config =
  environment.ENV === 'prod'
    ? {
        merchantIdsWithOffer: [
          '01HK5B0AJWMY6D1W1K030G0WS4',
          '01H4GXW5R719W619A46ME80Z76',
          '01H4GSK21K78HG0DPNT9KF04FA',
          '01HS0N9VAAG5CH0NJVQHP7051P',
          '01HK59C8026PKR0ABGJFJV1KFA',
          '01H2YHX0W5TZRZ1KPQYFVG1TB9',
          '01HK5AF22EAT0E1YAK93620241',
          '01H4GY7B7E1MCF1AP1859X0D3B',
          '01HK59XM4SGA4J0YTDPBKX04XE',
          '01HK5BEH4Z2RCJ1JH4DXY800WZ',
          '01H4GTR0QE7B2Q0FBH4GYF0KAX',
          '01H4GW3TQ8TER61XEP3CX20NGZ',
          '01H4GYKDRRSMFZ1957A6K003T8',
          '01HS0M4E1TC3Z41PCMVF1E17AM',
          '01H4GA2E6NG1HG0MV1ZE1Y0XDC',
          '01JAFHMEM7FDNA11TKDY9K1H46',
          '01H0PM6PEWW3GB001XMM5403SX',
          '01H0RVC4M1PR160ZDZH4FJ1BNS',
          '01H0PJQH9KE2J81H97MKWF1T6B',
          '01H0MPC7AZSTP60Y82CESR0DKM',
          '01H0PCEW85WP3G173CYKR81PEN',
          '01H0RYY5HQN00D0KTHMZJ31AHJ',
          '01H0PWNZQEVZZ5072FZA6Q0HP4',
          '01H0PXKTZAC6XA05XP00ZM1KK0',
          '01H0PRRG2YG29A0RSMFSSS01JT',
          '01H0S1Z6RGRWYF1VNYKK3N197N',
          '01H0RWDTXY1FPR0ZNBC7QP1Q2S',
          '01GX5YWRTMYG7B0F97MQG50WQ0',
          '01GR9403928VWK15TGKRR710YX',
          '01GR96HHCAD7WM1K1KJCE71T01',
        ],
        sourceProductId: '01JBTGXEZ0M1FP0CR93WPV06PS',
        targetProductId: '01JD119T8658TB0B9RXFKZ0F8Y',
      }
    : {
        merchantIdsWithOffer: ['01GGENJCCZY27F1M9HD8JS0ZEW'],
        sourceProductId: '01JBH28ARJVA4N1KDAW8P61YET',
        targetProductId: '01JCHZQMX4DRNJ1DPCKJV81GNR',
      };
