import { type PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppLoadingPage } from '~widgets/app-loading-page';

import { AttendanceStatus, useAttendanceStatus } from '~entities/attendance';
import { isAuthorizedAtom, isEmployeeRoleAllowedAtom } from '~entities/auth';
import {
  isPosInitializedAtom,
  selectedPosAtom,
  useGetPosListQuery,
} from '~entities/pos';

import isEqual from '@tinkoff/utils/is/equal';

import { useAtom, useAtomValue } from 'jotai';

export const PosInitializerWrapper = ({ children }: PropsWithChildren<{}>) => {
  const [isPosInitialized, setIsPosInitialized] = useAtom(isPosInitializedAtom);
  const [isPosChecked, setIsPosChecked] = useState(false);
  const isAuthorized = useAtomValue(isAuthorizedAtom);
  const hasAccess = useAtomValue(isEmployeeRoleAllowedAtom);
  const { pathname } = useLocation();

  const isAuthSuccessful = !!isAuthorized && !!hasAccess;
  const isApplicationsTab = pathname === '/applications';
  const attendanceStatus = useAttendanceStatus({
    enabled: isAuthSuccessful,
  });
  const [selectedPos, setSelectedPos] = useAtom(selectedPosAtom);

  const posList = useGetPosListQuery({
    enabled: !isPosInitialized && isAuthSuccessful,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (!isAuthorized) {
      setSelectedPos(null);
    }
  }, [setSelectedPos, isAuthorized]);

  const navigate = useNavigate();
  const shouldCheckPos = isApplicationsTab && !isPosChecked && selectedPos;

  /**
   * for checked-in FULL_TIME employees we need to preselect POS
   * from last attendance log after successful authorization
   */
  useEffect(() => {
    if (!isAuthSuccessful || !posList.data || isPosInitialized) {
      return;
    }

    if (
      attendanceStatus.data &&
      attendanceStatus.data?.status !== AttendanceStatus.CheckOut &&
      !selectedPos
    ) {
      const preselectedPos =
        posList.data?.find((pos) => pos.id === attendanceStatus.data?.posId) ??
        null;
      setSelectedPos(preselectedPos);
    }

    setIsPosInitialized(true);
  }, [
    isAuthSuccessful,
    posList,
    attendanceStatus,
    selectedPos,
    setSelectedPos,
    setIsPosInitialized,
    isPosInitialized,
  ]);

  useEffect(() => {
    if (!isAuthSuccessful || !posList.data || !shouldCheckPos) {
      return;
    }

    /**
     * before showing applications to the agent, we should check that
     * their selected pos is still available for them
     * if it is not, we should force the agent to reselect the pos
     */
    const selectedPosInList = posList.data.find(
      ({ id }) => id === selectedPos.id
    );

    if (!selectedPosInList) {
      setSelectedPos(null);
      navigate('/pos-select?redirectUrl=/applications', { replace: true });
    }

    if (selectedPosInList && !isEqual(selectedPos, selectedPosInList)) {
      setSelectedPos(selectedPosInList);
    }

    setIsPosChecked(true);
  }, [
    isAuthSuccessful,
    posList,
    selectedPos,
    setSelectedPos,
    navigate,
    isPosInitialized,
    setIsPosInitialized,
    shouldCheckPos,
  ]);

  if (shouldCheckPos) {
    return <AppLoadingPage />;
  }

  return <>{children}</>;
};
