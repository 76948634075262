import type { PropsWithChildren } from 'react';

// import logo from '~shared/images/logo.svg';
import holidayLogo from '~shared/images/holiday-logo.svg';

import { Row } from '@breeze-platform-ui/layout';

export const HeaderWithLogo = ({ children }: PropsWithChildren) => {
  return (
    <Row
      height={36}
      width="100%"
      alignCross="center"
      alignMain="between"
      data-qa-type="header"
      gaps={8}
    >
      {/* <img src={logo} alt="" height="36" width="120" /> */}
      <img src={holidayLogo} alt="" height="36" width="153" />
      {children}
    </Row>
  );
};
