import type { GetGiftPaymentsParams } from './gift-payments-api';

export const giftPaymentsKeys = {
  all: () => ['gift-payments'],
  settings: () => [...giftPaymentsKeys.all(), 'settings'],
  settingsByParams: (params: Partial<GetGiftPaymentsParams>) => [
    ...giftPaymentsKeys.settings(),
    params.merchantId,
    params.posId,
  ],
};
