import { useNavigate, useParams } from 'react-router-dom';

import { SocialLeadDetails } from '~widgets/social-lead-details';

import { useSocialLead } from '~entities/social-lead';

import errorImg from '~shared/images/error.svg';
import { Loader } from '~shared/ui/loader';
import { NavBackButton } from '~shared/ui/nav-back-button';
import { Screen } from '~shared/ui/screen';
import { Warning } from '~shared/ui/warning';

import Button from '@breeze-platform-ui/button';

export const SocialLeadDetailsPage = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const leadQuery = useSocialLead(id);

  const handleGoBack = () => navigate('/social-leads');

  const getContent = () => {
    if (leadQuery.data) {
      return <SocialLeadDetails lead={leadQuery.data} />;
    }

    if (leadQuery.isLoading) {
      return <Loader centered />;
    }

    return (
      <Warning
        title={leadQuery.isError ? 'Something went wrong' : 'Lead is not found'}
        description={leadQuery.isError && 'Please, try again later'}
        icon={errorImg}
        footerContent={
          <Button size="m" wide onClick={handleGoBack}>
            Back to my leads
          </Button>
        }
      />
    );
  };

  return (
    <Screen header={<NavBackButton onClick={handleGoBack} />}>
      {getContent()}
    </Screen>
  );
};
