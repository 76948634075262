import type { SocialMediaPlatform } from '~entities/social-lead';
import type { SocialLead } from '~entities/social-lead';

import { nonNullableValue } from '~shared/types/non-nullable-value';

const socialPlatformNameMap: Record<
  Exclude<SocialMediaPlatform, 'OTHER'>,
  string
> = {
  FACEBOOK: 'Facebook',
  TIKTOK: 'TikTok',
};
export function getSocialPlatformText(lead: SocialLead): string {
  if (lead.socialMediaPlatform === 'OTHER') {
    return nonNullableValue(lead.socialMediaPlatformOther);
  }

  return socialPlatformNameMap[lead.socialMediaPlatform];
}
