import { shiftTimeToStr } from '~entities/shedule/lib/shift-time-to-str';
import { getWorkDayTitle } from '~entities/shedule/lib/work-day-type-to-str';

import Loader from '@breeze-platform-ui/loader';
import Text from '@breeze-platform-ui/text';
import cn from 'classnames';
import { format } from 'date-fns';

import styles from './work-day-details.module.css';

import type { DailySchedule } from '../../schedule';
import { ShiftPoses } from '../shift-poses/shift-poses';

type Props = {
  schedule: DailySchedule | null;
  isLoading?: boolean;
};

export const WorkDayDetails: React.FC<Props> = ({ schedule, isLoading }) => {
  if (isLoading) {
    return <Loader centered />;
  }
  return (
    <div className={styles.container} data-qa-type="schedule-details">
      <Text size={13} color="rgba(0,0,0,0.54)" dataQaType="work-day-date">
        {format(new Date(), 'EEEE, LLLL d')}
      </Text>

      {schedule ? (
        <>
          <h2 className={styles.title}>
            <span>{getWorkDayTitle(schedule)}</span>
            <span>
              {schedule.type === 'WORK' && schedule.shiftTime && (
                <>
                  <br />
                  {shiftTimeToStr(schedule.shiftTime)}
                </>
              )}
            </span>
          </h2>
          {!!schedule.posList?.length && (
            <ShiftPoses posList={schedule.posList} />
          )}
        </>
      ) : (
        <h2 className={cn(styles.title, styles.errorTitle)}>
          No schedule. <br />
          Contact your ASM
        </h2>
      )}
    </div>
  );
};
