/* eslint-disable complexity */

/* eslint-disable max-statements */
import { useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import type { ActiveOffer } from '~features/manage-offer';
import {
  OfferContainer,
  ManageOfferByPhone,
  ManageOfferByCode,
} from '~features/manage-offer';
import { ManageOrderItems } from '~features/manage-order-items';
import type { SetupLoanFormValues } from '~features/setup-loan';
import { SetupLoan } from '~features/setup-loan';

import { useGiftPaymentRatesByTerm } from '~entities/gift-payment';
import { useSearchOfferByPhoneMutation } from '~entities/offer';
import type { OrderItem } from '~entities/order-item';
import { calculateItemsPrice } from '~entities/order-item';
import { coupleOrderItems } from '~entities/order-item/lib/couple-order-items';
import { decoupleOrderItems } from '~entities/order-item/lib/decouple-order-items';
import type {
  ProductType,
  OrderParams,
  Product,
  PaymentPlan,
} from '~entities/product';
import { isItemNotFoundError } from '~entities/product';
import { isProduct, isZeroRate } from '~entities/product';
import { useGetProductQuery } from '~entities/product/model';

import type { RequestError } from '~shared/scp-client/errors';
import { nonNullableValue } from '~shared/types/non-nullable-value';
import { Island } from '~shared/ui/island';
import { Loader } from '~shared/ui/loader';

import Button from '@breeze-platform-ui/button';
import { Box, Col } from '@breeze-platform-ui/layout';

import styles from './loan-calculator.module.css';

import type { LoanCalculatorFormValues } from '../loan-calculator-form-values';

type Props = {
  isPreApproved?: boolean;
  type: ProductType;
  initialOffer?: ActiveOffer;
  initialPromoCode?: string;
  initialValue?: Partial<LoanCalculatorFormValues>;
  onFieldCompleted?: (value: Partial<LoanCalculatorFormValues>) => void;
  onSubmit: (value: LoanCalculatorFormValues) => void;
  onCancelOfferClicked: () => void;
};

const MIN_DEFAULT = 0;
const MAX_DEFAULT = Infinity;

export const LoanCalculator: React.FC<Props> = ({
  isPreApproved,
  type,
  initialOffer,
  initialPromoCode,
  initialValue,
  onFieldCompleted,
  onSubmit,
  onCancelOfferClicked,
}) => {
  const paymentPlans = useRef<PaymentPlan[]>([]);
  const [items, setItems] = useState<OrderItem[]>(initialValue?.items ?? []);
  const [isSearchOfferError, setIsSearchOfferError] = useState(false);
  const [isSearchCodeError, setIsSearchCodeError] = useState(false);
  const [activeOffer, setActiveOffer] = useState<ActiveOffer | undefined>(
    initialOffer
  );
  const [activePromoCode, setActivePromoCode] = useState<string | undefined>(
    initialPromoCode
  );
  const [isOfferOpened, setIsOfferOpened] = useState<boolean>(
    !!activeOffer || !!activePromoCode
  );

  const totalPrice = useMemo(() => calculateItemsPrice(items), [items]);
  const methods = useForm<SetupLoanFormValues>({
    mode: 'all',
    defaultValues: initialValue,
  });
  const {
    getValues,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = methods;

  const checkOfferMutation = useSearchOfferByPhoneMutation({ retry: 0 });

  async function handleProductDetailsLoaded(product?: Product | OrderParams) {
    const productId = isProduct(product) ? product.productId : undefined;
    const campaignId = isProduct(product) ? product.campaignId : undefined;

    await trigger('downPayment');

    const selectedPeriod = getValues('period');
    // we should reset selected period only if it is empty or
    // current selected period is not available for the new product
    if (
      isProduct(product) &&
      (!selectedPeriod || !product.termsInMonths.includes(+selectedPeriod))
    ) {
      setValue('period', `${product.termsInMonths[0]}`);
    }

    const maxTotalPrice = product?.maxOrderTotalAmount || MAX_DEFAULT;
    const minTotalPrice = product?.minOrderTotalAmount || MIN_DEFAULT;
    let invalidLoanMessage: string | undefined;
    if (totalPrice > maxTotalPrice) {
      invalidLoanMessage = `There is no products with requested sum. Max order amount:  ${maxTotalPrice}`;
    }
    if (totalPrice < minTotalPrice) {
      invalidLoanMessage = `There is no products with requested sum. Min order amount: ${minTotalPrice}`;
    }

    onFieldCompleted?.({
      ...getValues(),
      paymentPlans: paymentPlans.current,
      invalidLoanMessage,
      items,
      totalPrice,
      productId,
      campaignId,
    });
  }

  const offerProductId = isZeroRate(type)
    ? undefined
    : activeOffer?.offer.posProductId;

  const {
    data: loanDetails,
    isFetching,
    isLoading,
    error: getProductError,
  } = useGetProductQuery(
    {
      orderItems: items,
      productType: type,
      offerProductId,
    },
    {
      enabled: items.length > 0,
      onSettled: handleProductDetailsLoaded,
    }
  );
  const giftPaymentRatesByTermQuery = useGiftPaymentRatesByTerm();

  const isDataLoading = isFetching || giftPaymentRatesByTermQuery.isLoading;

  useEffect(() => {
    if (
      getProductError &&
      isItemNotFoundError(getProductError as RequestError)
    ) {
      setItems([]);
    }
  }, [getProductError, setItems]);

  const loanFound = isProduct(loanDetails);
  const maxTotalPrice = loanDetails?.maxOrderTotalAmount || MAX_DEFAULT;
  const minTotalPrice = loanDetails?.minOrderTotalAmount || MIN_DEFAULT;
  const isLoanAmountExceeded = totalPrice > maxTotalPrice;
  const isLoanAmountBelowMin = totalPrice < minTotalPrice;
  const isDownPaymentInvalid = typeof errors.downPayment !== 'undefined';
  const isLoanInvalid =
    isLoanAmountExceeded || isLoanAmountBelowMin || isDownPaymentInvalid;

  const submitButtonDisabled = isLoanInvalid || isLoading || !loanFound;

  const showLoanDetails =
    totalPrice > 0 &&
    !isLoanAmountExceeded &&
    !isLoanAmountBelowMin &&
    loanFound;

  const productId =
    !isFetching && loanFound ? loanDetails.productId : undefined;
  const campaignId =
    !isFetching && loanFound ? loanDetails.campaignId : undefined;

  const offerDetails = activeOffer
    ? {
        id: activeOffer.offer.id,
        productId: activeOffer.offer.posProductId,
        offerPhone: activeOffer.phone,
      }
    : undefined;

  const submitLoanDetails = async () => {
    const offerPhone = activeOffer?.phone;

    // we need to check if the offer is still active
    // before creating application
    if (offerPhone) {
      const checkOfferResult = await checkOfferMutation
        .mutateAsync(offerPhone)
        .catch(() => ({ status: 'NOT_FOUND' }));

      if (checkOfferResult.status === 'NOT_FOUND') {
        setActiveOffer(undefined);
        return;
      }
    }
    onSubmit({
      ...getValues(),
      paymentPlans: paymentPlans.current,
      items,
      totalPrice,
      productId: nonNullableValue(productId),
      offer: offerDetails,
      promoCode: activePromoCode,
      campaignId,
    });
  };

  const handleSettingsChanged = () => {
    onFieldCompleted?.({
      ...methods.getValues(),
      invalidLoanMessage: isDownPaymentInvalid
        ? 'Downpayment error'
        : undefined,
      paymentPlans: isLoanInvalid ? [] : paymentPlans.current,
      items,
      totalPrice,
      productId: nonNullableValue(productId),
      campaignId,
      offer: offerDetails,
      promoCode: activePromoCode,
      isSearchOfferError: isSearchOfferError || undefined,
      isSearchCodeError: isSearchCodeError || undefined,
    });
  };

  const handlePaymentPlansChanged = useCallback((values: PaymentPlan[]) => {
    paymentPlans.current = values;
  }, []);

  const handleItemsChanged = (newItems: OrderItem[]) => {
    setItems(decoupleOrderItems(newItems));
    if (newItems.length === 0) {
      onFieldCompleted?.({ items: [] });
    }
  };

  const shouldOfferGiftPayments = !activeOffer && !isZeroRate(type);
  const giftPaymentRatesByTerm = shouldOfferGiftPayments
    ? giftPaymentRatesByTermQuery.data
    : undefined;

  return (
    <>
      <Col gaps={20} alignCross="stretch">
        <Island>
          <ManageOrderItems
            type={type}
            orderItems={coupleOrderItems(items)}
            minPrice={minTotalPrice}
            maxPrice={maxTotalPrice}
            /**
             * items update automatically triggers loan details request
             * and after that "onFieldCompleted" with new data is called =>
             * no need to call it manually after items have changed
             */
            onChangeItems={handleItemsChanged}
          />
        </Island>
        {!isZeroRate(type) && items.length > 0 && (
          <Island style={{ paddingBottom: 0 }}>
            <OfferContainer
              isOpened={isOfferOpened}
              onToggle={() => {
                if (activeOffer) {
                  onCancelOfferClicked();
                }
                setIsOfferOpened((opened) => !opened);
                setActiveOffer(undefined);
                setActivePromoCode(undefined);
              }}
            >
              {(source) =>
                source === 'MOBILE' ? (
                  <ManageOfferByPhone
                    activeOffer={activeOffer}
                    onUpdateOffer={setActiveOffer}
                    onCancelOffer={() => {
                      onCancelOfferClicked();
                      setActiveOffer(undefined);
                    }}
                    onSearchOfferError={() => setIsSearchOfferError(true)}
                  />
                ) : (
                  <ManageOfferByCode
                    activeCode={activePromoCode}
                    onUpdateCode={setActivePromoCode}
                    onSearchCodeError={() => setIsSearchCodeError(true)}
                  />
                )
              }
            </OfferContainer>
          </Island>
        )}
        {isDataLoading && (
          <Box margin="0 0 24px 0">
            <Loader size="m" centered />
          </Box>
        )}
        {!isDataLoading && showLoanDetails && (
          <form
            data-qa-type="setup-loan-form"
            className={styles.form}
            onBlur={handleSettingsChanged}
            onSubmit={handleSubmit(submitLoanDetails)}
          >
            <Col gaps={24} alignCross="stretch" margin="0 0 24px">
              <Island>
                <FormProvider {...methods}>
                  <SetupLoan
                    totalPrice={totalPrice}
                    loanParams={nonNullableValue(loanDetails)}
                    giftPaymentRatesByTerm={giftPaymentRatesByTerm}
                    onPaymentPlansChanged={handlePaymentPlansChanged}
                  />
                </FormProvider>
              </Island>
              {items.length > 0 && (
                <Button
                  type="submit"
                  size="l"
                  wide
                  disabled={submitButtonDisabled}
                >
                  {isPreApproved ? 'Apply' : 'Start a new application'}
                </Button>
              )}
            </Col>
          </form>
        )}
      </Col>
    </>
  );
};
/* eslint-enable max-statements */
/* eslint-enable complexity */
