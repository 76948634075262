import { Warning } from '~shared/ui/warning';

import Button from '@breeze-platform-ui/button';
import { Col } from '@breeze-platform-ui/layout';
import { TuiIconTdsIllustrationsCommonDrawerEmptyLight } from '@tui-react/proprietary-icons';

export const EmptyWarning = ({
  onAdd,
  onBack,
}: {
  onAdd: () => void;
  onBack: () => void;
}) => (
  <Warning
    title="No leads"
    icon={
      <TuiIconTdsIllustrationsCommonDrawerEmptyLight iconSize={180 as any} />
    }
    footerContent={
      <Col width={240} alignCross="center" margin="0 auto" gaps={16}>
        <Button onClick={onAdd} wide>
          Submit a new lead
        </Button>
        <Button onClick={onBack} wide theme="secondary">
          Go back
        </Button>
      </Col>
    }
  />
);
